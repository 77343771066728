<template>
  <section class="course-wizard wizard-background">
    <div class="container">
      <div class="text-center admission-container">
        <div class="row">
          <div class="col-md-12 mt60">
            <div class="generic-wizard-heading">Select Your Preference</div>
            <p class="generic-wizard-subheading">
              Click on the tabs below to select your respective class.
              <br />
              Don’t worry you are just a few clicks away.
            </p>
          </div>
        </div>

        <a
          class="board-box"
          href="https://onlineregistration.kips.edu.pk/EveningCoaching"
          target="_blank"
        >
          <div class="course-icon">
            <img src="/assets/images/homepage/online-icon-1.svg" />
          </div>

          <div class="board-box-head">Evening Coaching</div>
        </a>

        <a
          class="board-box"
          href="https://onlineregistration.kips.edu.pk/EntryTests"
          target="_blank"
        >
          <div class="course-icon pl10">
            <img src="/assets/images/homepage/online-icon-2.svg" />
          </div>

          <div class="board-box-head">Entry Test Preparation</div>
        </a>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import {
  classes as classesStoreKeys,
  course as courseStoreKeys,
} from "../store/keys";

export default {
  // Client-side only
  metaInfo: {
    title: "Online Admissions | KIPS LMS",
    titleTemplate: "",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },
  mounted() {},
  created() {},

  data() {
    return {};
  },
  asyncData({ store, route }) {},
  computed: {},
  // Server-side only
  serverPrefetch() {},
  methods: {},
};
</script>
